import './App.css';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

import Homepage from './pages/Homepage';
import About from './pages/About';


const App = () => {

  return (
    <>
            {/* This is the alias of BrowserRouter i.e. Router */}
            <Router>
                <Routes>
                    {/* routes */}
                    <Route exact path="/" element={<Homepage />}/>
                    <Route exact path="/About" element={<About />}/>

                </Routes>
            </Router>
        </>
  );
}

export default App;
