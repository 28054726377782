import React from "react";
import { SiteHeader } from "./SiteHeader";

import {
    Box,
    Grommet,
    Heading,
    Image,
    Paragraph,
    Text,
} from 'grommet'

import { Link } from "react-router-dom";

import climb from '../climb.jpg'
import prof_pic from '../prof_pic.png'
import leo from '../leo.png'
import q53 from '../q53.png'
import penguin from '../penguin.png'



const Homepage = () => {

    return (
        <div>
            <Grommet id="home">
                <SiteHeader />
                <Box pad={'medium'} align="center" direction='row' justify='between' >
                    <Box>
                        <Box direction="row" align="center" justify="between">
                            <Box width={"medium"}>
                                <Heading level="1" color="#47B0EC" style={{ fontFamily: 'Berlin Sans FB', fontWeight:'lighter'}}>
                                    Hey, I'm Will
                                </Heading>
                            </Box>
                        </Box>
                        <Heading level="2"  style={{ fontFamily: 'Berlin Sans FB', fontWeight:'lighter' }}>
                            I'm a full-stack developer with a specialty in cybersecurity. <br />I currently hold the Comptia 
                            Security+ certification and study at the University of Michigan
                        </Heading>
                        <Link to={"/About"}>
                            <Text color="text-strong" >More about me!</Text> 
                        </Link>
                    </Box>
                    <Box pad={'large'} width={"medium"}>
                        <Image fit="cover" src={prof_pic}/>
                    </Box>
                    
                </Box>

                <section id="projects">
                    <Box pad={'medium'}>
                        <Heading level="1" style={{ fontFamily: 'Berlin Sans FB', fontWeight:'lighter' }}>
                            My Projects
                        </Heading>

                    </Box>

                    {/* Leo */}
                    <Box pad={'medium'} align="center" direction='row' justify='between' background={'light-2'}>
                        <Box pad={'medium'} width={"45%"}>
                            <Heading level='3'>Leo News</Heading>
                            <Image src={leo}/>
                            <a href="https://www.leo-news.org" style={{ textDecoration: 'none', marginTop: '20px', display: 'inline-block' }} target="_blank" rel="noopener noreferrer"> Check it out!</a>
                        </Box>
                        <Box width={"50%"}>
                            <Heading level="3" style={{ fontFamily: 'Berlin Sans FB', fontWeight:'lighter', margin: '0' }}>
                                What it is
                            </Heading>
                            <Paragraph fill>
                                Leo is an open source, non-profit news network. This means that anyone can write articles. All written articles are 
                                peer reviewed, and the top reviewed articles are published for our readers. 
                            </Paragraph>
                            <Heading level="3" style={{ fontFamily: 'Berlin Sans FB', fontWeight:'lighter', margin: '0' }}>
                               How it works
                            </Heading>
                            <Paragraph fill>
                                Leo is a full stack application consisting of a database on the backend as well as a website and mobile app on 
                                the frontend. The backend is built using Django and hosted on an AWS EC2 instance connected to 
                                AWS RDS running a MySQL database. The frontend website is built using React with Grommet and hosted on an AWS S3
                                Bucket with cloudfront. The frontend mobile app uses SwiftUI and is available for download on the Apple App Store.
                            </Paragraph>
                        </Box>
                    </Box>


                    {/* Spoof Proof */}
                    <Box pad={'medium'} align="center" direction='row' justify='between'>
                        <Box width={"50%"}>
                            <Heading level='3'>
                                Spoof Proof <br />(Department of Defense Research Project)
                            </Heading>
                            <Heading level="3" style={{ fontFamily: 'Berlin Sans FB', fontWeight:'lighter', margin: '0' }}>
                                What it is
                            </Heading>
                            <Paragraph fill>
                                Spoof Proof was a research project sponsored by the US Department of Defense in partnership
                                with the 101st Airborne division of the US Army and the University of Michigan. The 
                                goals was to find a cost effective way to protect US radar from adversary artillery fire.
                            </Paragraph>
                            <Heading level="3" style={{ fontFamily: 'Berlin Sans FB', fontWeight:'lighter', margin: '0' }}>
                               Results
                            </Heading>
                            <Paragraph fill>
                                My team developed plans for a multi-domain system which utilizes radar-spoofing technology to draw adversary fire to a predetermined location, thus
                                allowing for friendly forces to locate the adversary artillery and take it out of commission, saving countless lives and billions of dollars in military spending each year. The project was pitched to a Three-Star General in the 
                                US Army with the hope of beginning development in early 2025.
                            </Paragraph>
                        </Box>
                        <Box pad={'medium'} width={"45%"}>
                            <Image src={q53}/>
                        </Box>
                    </Box>

                    {/* Playful Penguin */}
                    <Box pad={'medium'} align="center" direction='row' justify='between' background={'light-2'}>
                        <Box pad={'medium'} width={"30%"}>
                            <Heading level='3'>Playful Penguin</Heading>
                            <Image src={penguin}/>
                        </Box>
                        <Box width={"50%"}>
                            <Heading level="3" style={{ fontFamily: 'Berlin Sans FB', fontWeight:'lighter', margin: '0' }}>
                                What it is
                            </Heading>
                            <Paragraph fill>
                                My first personal project. Playful Penguin is a mobile game I developed in my senior year of high school using Unity game 
                                engine. It was made available for download on the Apple App Store in 2020. While it is no longer available for public download,
                                I still move it onto my phone each time I get an upgrade, and am happy to send a screen recording to anyone 
                                who is curious.
                            </Paragraph>
                            <Heading level="3" style={{ fontFamily: 'Berlin Sans FB', fontWeight:'lighter', margin: '0' }}>
                               How it works
                            </Heading>
                            <Paragraph fill>
                                Playful Penguin was developed on Unity Game Engine using C# scripts for random level generation.
                            </Paragraph>
                        </Box>
                    </Box>

                </section>
            
            </Grommet>
             {/* CSS for smooth scrolling */}
             <style>
                {`
                html {
                    scroll-behavior: smooth;
                }
                `}
            </style>
            
        </div>
    )
};
 
export default Homepage;

