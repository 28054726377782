import {
    Box,
    Header, 
    Image,
    Text,
  } from 'grommet'

  import { Link } from "react-router-dom";
  import React from 'react';
  import wg from '../wg.png'


export const SiteHeader = () => {
    return (
            <Header
            background={"white"}
            fill="horizontal"
            pad={{ horizontal: 'medium', vertical: 'small' }}
            elevation='medium'
            style={{ position: 'sticky', top: 0, zIndex: 1000 }}  // Added z-index to keep it on top
            >
                <Box
                direction="row"
                align="start"
                gap="medium"
                pad={{ vertical: 'none' }}
                >
                    <a href="/#home" style={{ textDecoration: 'none' }}>
                        <Box direction="row" gap="xsmall" wrap align="center">
                            <Image src={wg} width="50px"/>
                        </Box>
                    </a>
                </Box>
                <Box direction='row' gap={'large'}>
                    <a href={"/#projects"} style={{ textDecoration: 'none' }}>
                        <Text color="text-strong" >Projects</Text> 
                    </a>
                    <Link to={"/About"} style={{ textDecoration: 'none' }}>
                        <Text color="text-strong" >About</Text> 
                    </Link>
                </Box>
                

                
            </Header>
    );
  };