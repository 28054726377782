import React from "react";
import { SiteHeader } from "./SiteHeader";
import { useEffect } from 'react';

import {
    Box,
    Carousel,
    Grommet,
    Heading,
    Image,
    Paragraph,
} from 'grommet'


import triathlon from '../triathlon.png'
import skydive from '../skydive.png'
import ski from '../ski.png'
import mich from '../mich.png'
import security from '../security.png'



const About = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
      
    return (
        <div>
            <Grommet>
                <SiteHeader />
                <Box pad={'medium'} align="center" >
                    <Heading level="1" color="#47B0EC" style={{ fontFamily: 'Berlin Sans FB', fontWeight:'lighter'}}>
                        Get to know me!
                    </Heading>
                </Box>

                <Box pad={{horizontal: 'medium'}}>
                    <Heading level="2" style={{ fontFamily: 'Berlin Sans FB', fontWeight:'lighter'}}>
                            Professional:
                    </Heading>
                </Box>

                <Box justify="center" align="center" gap={'medium'} direction="row">
                    <Box align="center" >
                        <Box height={'medium'} width={'large'}>
                            <Image src={mich} fit="cover"/>
                        </Box>
                    </Box>
                    <Box align="center" >
                        <Box height={'medium'} width={'large'}>
                            <Image src={security} fit="cover"/>
                        </Box>
                    </Box>
                </Box>
                <Box justify="center" align="center" gap={'medium'} direction="row">

                    <Box align="center" >
                        <Box align='center' height={'medium'} width={'large'}>
                            <Heading level="3"  style={{ fontFamily: 'Berlin Sans FB', fontWeight:'lighter' }}>
                                I am currently a senior studying computer science through the University of Michigan College of Engineering.
                            </Heading>
                        </Box>
                        
                    </Box>
                    <Box align="center" >
                        <Box align="center" height={'medium'} width={'large'}>
                            <Heading level="3"  style={{ fontFamily: 'Berlin Sans FB', fontWeight:'lighter' }}>
                                I have experience building full-stack applications, hold the Comptia Security+ certification, and am in the 
                                top 18% of learners on TryHackMe, a cybersecurity training site.
                            </Heading>
                        </Box>
                    </Box>
                </Box>

                <Box background={"light-2"} pad={{bottom:'large'}}>
                    <Box pad={{horizontal: 'medium'}}>
                        <Heading level="2" style={{ fontFamily: 'Berlin Sans FB', fontWeight:'lighter'}}>
                                Personal:
                        </Heading>
                    </Box>
                    <Box direction="row" justify="center" align='center' pad={'medium'} gap={'xlarge'}>
                        <Heading level="3"  style={{ fontFamily: 'Berlin Sans FB', fontWeight:'lighter' }} >
                            Outside the tech world, I compete in triathlons and train in muay thai. I am currently an all-world athlete
                            in the Ironman triathlon with times in the top 15% of athletes worldwide
                        </Heading>
                        <Box width='large'>
                            <Carousel fill play={30000}> 
                                <Box height="medium" width="large" >
                                    <Image fit="cover" src={triathlon} />
                                </Box>

                                <Box height="medium" width="large" >
                                    <Image fit="cover" src={ski} />
                                </Box>

                                <Box height="medium" width="large" >
                                    <Image fit="cover" src={skydive} />
                                </Box>
                            </Carousel>
                        </Box>
                    </Box>
                </Box>

            </Grommet>
             {/* CSS for smooth scrolling */}
             <style>
                {`
                html {
                    scroll-behavior: smooth;
                }
                `}
            </style>
            
        </div>
    )
};
 
export default About;

